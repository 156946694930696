<template>
  <div>
    <loader
      v-if="ShowProduct"
      object="#009bc2"
      color1="#ffffff"
      color2="#1763fd"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      disable-scrolling="false"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <b-overlay
        :show="ShowProduct"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <b-icon-controller
              font-scale="3"
              animation="cylon"
            />
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>

        <div class="card p-1">
          <div class="d-flex row">
            <div class="XjROLg col-lg-5 col-md-5 col-sm-12">

              <skeleton-loader-vue
                v-if="!data.picture"
                type="rect"
                :width="400"
                :height="400"
                animation="wave"
              />

              <div class="img-show-first">
                <img
                  :src="selectedImg"
                  alt="img-product"
                >
              </div>

              <span
                v-for="(item, index) in items"
                :key="index"
                class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                :class="{ active: actv === index }"
                @click="imgShow(item), actImg(index)"
              >
                <img :src="item.src">
              </span>

            </div>

            <div class="layout-right ml-md-2 w-100 col-lg-6 col-md-6 col-sm-12">
              <div class="od-pc-offer-title-contain">
                <div class="title-content">
                  <div class="title-first-column">
                    <div class="title-text">
                      <skeleton-loader-vue
                        v-if="!data.name"
                        type="rect"
                        :width="600"
                        :height="20"
                        animation="wave"
                      />
                      {{ data.name }}
                    </div>
                  </div>
                  <div class="title-first-column">
                    <div class="title-text" />
                  </div>
                </div>
                <div class="od-pc-offer-price-contain">
                  <div class="od-pc-offer-price-common">
                    <div class="price-content">
                      <div class="price-header">
                        <span class="price-name">{{ $t('price') }}</span><span class="num-name">.</span><span class="num-name">{{ $t('key-172') }}</span>
                      </div>
                      <div
                        class="step-price-wrapper brlr-6"
                      >
                        <div class="step-price-item">
                          <div class="price-box">
                            <span class="price-unit">฿</span><span class="price-text">{{ data.price }}</span>
                          </div>
                    <!-- <div class="price-box">
                      <span class="price-unit2">฿</span><span class="price-text2">11.00</span>
                    </div> --><br>
                          <div class="step-unit-box">
                            <span class="unit-text">1 {{ $t('key-172') }}</span>
                          </div>
                        </div>
                      <!-- <div class="step-price-item">
                    <div class="price-box">
                      <span class="price-unit">¥</span><span class="price-text">9.00</span>
                    </div>
                    <div class="price-box">
                      <span class="price-unit2">฿</span><span class="price-text2">9.00</span>
                    </div>
                    <div class="step-unit-box">
                      <span class="unit-text">≥ 6คู่</span>
                    </div>
                  </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="od-pc-offer-line">
                <div class="division-line" />
              </div>
              <div class="pc-sku-wrapper">
                <div
                  style="
                position: relative;
                border-bottom: 1px solid rgb(233, 233, 233);
              "
                >
                  <div
                    class="sku-module-wrapper sku-scene-wrapper"
                    style="padding-top: 15px; max-height: 194px"
                  >
                    <div class="sku-prop-module-name">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">
                        <!-- จำนวน -->
                        </font>
                      </font>
                    </div>
                    <div style="flex: 1 1 0%; overflow: hidden">
                      <div
                        v-for="item in 1"
                        id="sku-count-widget-wrapper"
                        :key="item.id"
                        class="count-widget-wrapper"
                      >
                        <div class="sku-item-wrapper">

                          <div class="sku-item-left">
                            <div class="discountPrice-price">
                              <font style="vertical-align: inherit">
                                <font style="vertical-align: inherit" /> </font><font style="vertical-align: inherit">
                                <font style="vertical-align: inherit">
                                  {{ $t('quantity') }}
                                </font>
                              </font>
                            </div>
                            <div class="sku-item-sale-num">
                              <font style="vertical-align: inherit">
                                <font style="vertical-align: inherit">
                                  {{ $t('key-169') }} {{ data.quantity }} {{ $t('key-111') }}
                                </font> </font><font style="vertical-align: inherit">
                                <font style="vertical-align: inherit" /> </font><font style="vertical-align: inherit">
                                <font style="vertical-align: inherit">
                                  {{ $t('key-170') }}
                                </font>
                              </font>
                            </div>
                          </div>
                          <div>
                            <!-- <b-form-spinbutton
                              id="sb-inline"
                              v-model="value"
                              inline
                              :max="data.quantity"
                            /> -->
                            <vue-numeric-input
                              v-model="value"
                              :min="0"
                              :max="data.quantity"
                              :step="1"
                            />

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sku-wrapper-expend-button" />
                </div>
              </div>
              <div class="mt-2">
                <button
                  class="btn btn-gradient-primary"
                  @click="AddCart"
                >
                  <i class="fa fa-shopping-cart" /> Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card p-1">
          <div class="od-pc-offer-title-contain">
            <div class="title-content">
              <div class="title-first-column">
                <div class="title-text">
                  {{ $t('productDetails') }}
                </div>
              </div>
              <div class="title-second-column">
                <div class="title-text title-second-text" />
              </div>
            </div>

            <!-- รายละเอียด -->
            <p> {{ data.details }}</p>
          </div>
          <div
            v-for="(item) in data.picture"
            :key="item"
            class="text-center"
          >
            <img
              :src="item"
              alt=""
              class="img-fluid"
            >
          </div>

        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { BFormSpinbutton, BOverlay } from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import SkeletonLoaderVue from 'skeleton-loader-vue'
// import ThisHeader from './component/ThisHeader.vue'
import VueNumericInput from 'vue-numeric-input'

export default {
  components: {
    BFormSpinbutton,
    SkeletonLoaderVue,
    BOverlay,
    // ThisHeader,
    VueNumericInput,
  },
  data() {
    return {
      value: 1,
      data: {},
      items: [],
      selectedImg: '',
      actv: 0,
    }
  },
  watch: {
    items: {
      handler(newItems) {
        if (newItems.length > 0 && !this.selectedImg) {
          this.selectedImg = newItems[0].src
        }
      },
      immediate: true,
    },
  },
  created() {
    this.interval = setInterval(() => {
      // this.getUserslist()
      this.GetData(this.$route.params.id)
    }, 10000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    this.GetData(this.$route.params.id)
  },
  methods: {
    imgShow(item) {
      this.selectedImg = item.src
    },
    actImg(id) {
      this.actv = id
    },
    formatValue(value) {
      if (value < 1000) return value
      return (value / 1000).toFixed(1)
    },
    async GetData(id) {
      const obj = {
        id,
      }
      this.$http
        .post('/product/GetProductById', obj)
        .then(response => {
          this.data = response.data
          this.items = []
          // eslint-disable-next-line no-restricted-syntax
          for (const item of response.data.picture) {
            // console.log(item)
            const show = {
              src: item,
              thumbnail: item,
            }
            this.items.push(show)
          }
        })
        .catch(error => console.log(error))
    },
    AddCart() {
      const formData = {
        name: this.data.name,
        // eslint-disable-next-line no-underscore-dangle
        _id: this.data._id,
        picture: this.data.picture,
        details: this.data.details,
        price: this.data.price,
        quantity: this.value,
      }
      // console.log(formData)
      this.$http
        .post('/cart/add', formData)
        .then(() => {
          this.GetData(this.$route.params.id)
          this.value = 1
          // eslint-disable-next-line no-underscore-dangle
          this.Success('เพิ่มสินค้าลงตะกร้าสำเร็จ')
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
